import { z } from 'zod';

export const emailSubsSchema = z.object({
  Firstname: z
    .string({
      required_error: 'Bu alan boş bırakılamaz.',
      invalid_type_error: 'Lütfen geçerli bir ad giriniz.',
    })
    .min(1, { message: 'Lütfen geçerli bir ad giriniz.' }),
  Lastname: z
    .string({
      required_error: 'Bu alan boş bırakılamaz.',
      invalid_type_error: 'Lütfen geçerli bir soyad giriniz.',
    })
    .min(1, { message: 'Lütfen geçerli bir soyad giriniz.' }),
  Email: z
    .string({
      required_error: 'Bu alan boş bırakılamaz.',
      invalid_type_error: 'Bu alan boş bırakılamaz.',
    })
    .email('Geçerli bir e-posta adresi girmeniz gerekmektedir.'),
  PhoneNumber: z.string().min(17, { message: 'Lütfen geçerli bir telefon numarası giriniz.' }).optional(),
  ExplicitConsent: z
    .boolean({
      required_error: 'Bu alan boş bırakılamaz.',
      invalid_type_error: 'Bu alan boş bırakılamaz.',
    })
    .refine((val) => {
      return val === true;
    }, 'Lütfen onaylayınız.'),
  ClarificationText: z
    .boolean({
      required_error: 'Bu alan boş bırakılamaz.',
      invalid_type_error: 'Bu alan boş bırakılamaz.',
    })
    .refine((val) => {
      return val === true;
    }, 'Lütfen onaylayınız.'),
  IsSubscribe: z
    .boolean({
      required_error: 'Bu alan boş bırakılamaz.',
      invalid_type_error: 'Bu alan boş bırakılamaz.',
    })
    .refine((val) => {
      return val === true;
    }, 'Lütfen onaylayınız.'),
});
