import { XCloseIcon } from '@/src/features/ui/icons/icons';
import { DeviceContext } from '@/src/store/contexts/device-context';
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';

export type IToast = {
  hide: () => void;
  show: (message: string, duration?: number, success?: boolean) => void;
};

const Toast = forwardRef<IToast>(function Toast(_, ref) {
  const [message, setMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const { isMobile } = useContext(DeviceContext);
  let timeout: NodeJS.Timeout;

  const show = (message: string, duration: number = 10000, success: boolean = true) => {
    setMessage(message);
    setIsSuccess(success);
    if (timeout) clearTimeout(timeout);
    if (duration)
      timeout = setTimeout(() => {
        setMessage(null);
      }, duration);
  };

  const hide = () => {
    if (timeout) clearTimeout(timeout);
    setMessage(null);
  };

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  if (!message) return <></>;

  return (
    <div
      className={`${isMobile ? 'left-[16px] right-[16px] top-[50px]' : 'right-[24px] top-[88px]'} fixed z-50 flex items-center rounded ${isSuccess ? 'bg-green600' : 'bg-destructive500'} p-3 text-sm font-semibold leading-5 text-white`}
      data-testid="toast"
    >
      <span data-testid="message">{message}</span>
      <span className={`${isMobile ? 'ml-auto' : ''} cursor-pointer p-2`} onClick={hide} data-testid="closeButton">
        <XCloseIcon width={16} height={16} />
      </span>
    </div>
  );
});

export default Toast;
